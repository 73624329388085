@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  @apply bg-primary;
}

body {
  @apply text-textColor;
  @apply bg-white;
  @apply bg-noise;
}

input[type="text"],
textarea {
  &:not(.rti--container input) {
    @apply block;
    @apply p-1;
    @apply bg-input bg-noise;
    @apply rounded-sm;
    @apply w-full;
  }
}

.rti--container {
  @apply bg-input bg-noise;
  @apply p-0;
  @apply py-1 px-2;
  @apply w-full;
  @apply flex flex-wrap;
  @apply rounded-none;
  @apply transition-colors;
  @apply border-none focus-within:outline-none focus-within:shadow-none focus-within:border-none;

  input {
    @apply bg-transparent;
    @apply focus:outline-none;
    @apply flex-1;
    @apply min-w-[200px];
    @apply rounded-none;
  }

  .rti--tag {
    @apply py-1 px-2;
    @apply bg-primary;
    @apply text-sm;
    @apply rounded-sm;
    @apply inline-flex items-center space-x-2;

    button {
      @apply m-0 p-0;
      @apply hover:text-gray-700;
    }
  }
}